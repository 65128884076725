import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

console.log(`
Olá, eu sou a Marcela Bomfim, Desenvolvedora Front-End!

          MMMMM   ::::::::    MMMM MMMMM
        MMMMM    :::::::::   MMMMM:  MMMMM
      MMMMM     ::::::::::  MMMMM::    MMMMM
    MMMMM      ::::: ::::: MMMMM:::      MMMMM
  MMMMM       :::::  :::::MMMMM::::        MMMMM
    MMMMM    :::::   ::::MMMMM:::::      MMMMM
      MMMMM :::::    :::MMMMM :::::    MMMMM
        MMMMM:::     ::MMMMM  :::::  MMMMM
          MMMMM      :MMMMM   ::::: MMMM
`);
